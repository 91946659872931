var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"color":"#5a4e42","background":"#edeae6"}},[_c('div',{staticClass:"hig",style:({
      background: `url(${
        'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/8d6bbfe19cef4c3ca015e0969348580b.png' +
        this.imgSuffix
      })`,
      'background-size': 'cover',
    })},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.imgSuffix),expression:"imgSuffix"}],staticClass:"img_loading",attrs:{"src":require("../../static/newImage/img_loading.png")}}),_c('img',{staticClass:"titlceCent",attrs:{"src":'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/18a1096765ae4e3a8919a38391f24961.png' +
        _vm.imgSuffix,"alt":""}})]),_c('div',{staticClass:"on"},[_c('div',{staticClass:"showBox"},[_c('div',{ref:"one",staticClass:"one",style:({
          background: `url(${
            'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/40d762322f2345a9815c1fea0df68617.png' +
            this.imgSuffix
          })`,
        })},[_c('div',[_c('div',{ref:"oneContet",staticClass:"contet"},[_c('div',{attrs:{"id":"font_center"}},[_vm._v("\n              "+_vm._s(_vm.arrList[1].item.length > 80
                  ? _vm.arrList[1].item.slice(0, 80) + "..."
                  : _vm.arrList[1].item)+"\n            ")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.toHomePath(8, { id: 4 })}}},[_vm._v("\n              查看更多"),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('div',{ref:"onePry",staticClass:"pry"}),_c('div',{ref:"oneADollar",staticClass:"ADollar"}),_c('div',{ref:"oneBDollar",staticClass:"BDollar"}),_c('div',{ref:"oneTree",staticClass:"tree"})])]),_c('div',{ref:"two",staticClass:"two",style:({
          background: `url(${
            'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/754a366fae184326b8ea682efd1d6dac.png' +
            this.imgSuffix
          })`,
        })},[_c('div',[_c('div',{ref:"twoContet",staticClass:"contet"},[_c('div',{attrs:{"id":"font_center"}},[_vm._v("\n              "+_vm._s(_vm.arrList[2].item.length > 80
                  ? _vm.arrList[2].item.slice(0, 80) + "..."
                  : _vm.arrList[2].item)+"\n            ")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.toHomePath(3, { id: 7 })}}},[_vm._v("\n              查看更多"),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('div',{ref:"twoPry",staticClass:"pry"}),_c('div',{ref:"twoADollar",staticClass:"ADollar"}),_c('div',{ref:"twoBDollar",staticClass:"BDollar"}),_c('div',{ref:"twoTree",staticClass:"tree"})])]),_c('div',{ref:"three",staticClass:"three",style:({
          background: `url(${
            'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/9375b364e0354ae885ce59c2406b5bfe.png' +
            this.imgSuffix
          })`,
        })},[_c('div',[_c('div',{ref:"threeContet",staticClass:"contet"},[_c('div',{attrs:{"id":"font_center"}},[_vm._v("\n              "+_vm._s(_vm.arrList[3].item.length > 80
                  ? _vm.arrList[3].item.slice(0, 80) + "..."
                  : _vm.arrList[3].item)+"\n            ")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.toHomePath(2, { id: 5 })}}},[_vm._v("\n              查看更多"),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('div',{ref:"threePry",staticClass:"pry"}),_c('div',{ref:"threeADollar",staticClass:"ADollar"}),_c('div',{ref:"threeBDollar",staticClass:"BDollar"}),_c('div',{ref:"threeTree",staticClass:"tree"})])]),_c('div',{ref:"four",staticClass:"four",style:({
          background: `url(${
            'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/ba6a44736102459aae53fc5a417e6197.png' +
            this.imgSuffix
          })`,
        })},[_c('div',[_c('div',{ref:"fourContet",staticClass:"contet"},[_c('div',{attrs:{"id":"font_center"}},[_vm._v("\n              "+_vm._s(_vm.arrList[4].item.length > 80
                  ? _vm.arrList[4].item.slice(0, 80) + "..."
                  : _vm.arrList[4].item)+"\n            ")])]),_c('div',{ref:"fourPry",staticClass:"pry"}),_c('div',{ref:"fourADollar",staticClass:"ADollar"}),_c('div',{ref:"fourBDollar",staticClass:"BDollar"}),_c('div',{ref:"fourTree",staticClass:"tree"})])]),_c('div',{ref:"five",staticClass:"five",style:({
          background: `url(${
            'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/9f5eeab3a8c44116b59c4054ed56b91c.png' +
            this.imgSuffix
          })`,
        })},[_c('div',[_c('div',{ref:"fiveContet",staticClass:"contet"},[_c('div',{attrs:{"id":"font_center"}},[_vm._v("\n              "+_vm._s(_vm.arrList[5].item.length > 80
                  ? _vm.arrList[5].item.slice(0, 80) + "..."
                  : _vm.arrList[5].item)+"\n            ")])]),_c('div',{ref:"fivePry",staticClass:"pry"}),_c('div',{ref:"fiveADollar",staticClass:"ADollar"}),_c('div',{ref:"fiveBDollar",staticClass:"BDollar"}),_c('div',{ref:"fiveTree",staticClass:"tree"})])]),_c('div',{ref:"six",staticClass:"six",style:({
          background: `url(${
            'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/db794b7a28ac4db1aad70cb62ddc255d.png' +
            this.imgSuffix
          })`,
        })},[_c('div',[_c('div',{ref:"sixContet",staticClass:"contet"},[_c('div',{attrs:{"id":"font_center"}},[_vm._v("\n              "+_vm._s(_vm.arrList[6].item.length > 80
                  ? _vm.arrList[6].item.slice(0, 80) + "..."
                  : _vm.arrList[6].item)+"\n            ")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.toHomePath(7, { id: 1 })}}},[_vm._v("\n              查看更多"),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('div',{ref:"sixPry",staticClass:"pry"}),_c('div',{ref:"sixADollar",staticClass:"ADollar"}),_c('div',{ref:"sixBDollar",staticClass:"BDollar"}),_c('div',{ref:"sixTree",staticClass:"tree"})])]),_c('div',{ref:"seven",staticClass:"seven",style:({
          background: `url(${
            'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/574de7495c614de4bfb8756aa8a3da00.png' +
            this.imgSuffix
          })`,
        })},[_c('div',[_c('div',{ref:"sevenContet",staticClass:"contet"},[_c('div',{attrs:{"id":"font_center"}},[_vm._v("\n              "+_vm._s(_vm.arrList[7].item.length > 80
                  ? _vm.arrList[7].item.slice(0, 80) + "..."
                  : _vm.arrList[7].item)+"\n            ")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.toHomePath(2, { id: 3 })}}},[_vm._v("\n              查看更多"),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('div',{ref:"sevenPry",staticClass:"pry"}),_c('div',{ref:"sevenADollar",staticClass:"ADollar"}),_c('div',{ref:"sevenBDollar",staticClass:"BDollar"}),_c('div',{ref:"sevenTree",staticClass:"tree"})])]),_c('div',{ref:"eight",staticClass:"eight",style:({
          background: `url(${
            'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/6c4dac1f4ce54b9eb2a8516b2ff5b96e.png' +
            this.imgSuffix
          })`,
        })},[_c('div',[_c('div',{ref:"eightContet",staticClass:"contet"},[_c('div',{attrs:{"id":"font_center"}},[_vm._v("\n              "+_vm._s(_vm.arrList[8].item.length > 80
                  ? _vm.arrList[8].item.slice(0, 80) + "..."
                  : _vm.arrList[8].item)+"\n            ")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.toHomePath(5, { id: 2 })}}},[_vm._v("\n              查看更多"),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('div',{ref:"eightPry",staticClass:"pry"}),_c('div',{ref:"eightADollar",staticClass:"ADollar"}),_c('div',{ref:"eightBDollar",staticClass:"BDollar"}),_c('div',{ref:"eightTree",staticClass:"tree"})])]),_c('div',{ref:"nine",staticClass:"nine",style:({
          background: `url(${
            'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/31847644470e4985af62054e9aa37b5f.png' +
            this.imgSuffix
          })`,
        })},[_c('div',[_c('div',{ref:"nineContet",staticClass:"contet"},[_c('div',{attrs:{"id":"font_center"}},[_vm._v("\n              "+_vm._s(_vm.arrList[9].item.length > 80
                  ? _vm.arrList[9].item.slice(0, 80) + "..."
                  : _vm.arrList[9].item)+"\n            ")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.toHomePath(10, { id: 9 })}}},[_vm._v("\n              查看更多"),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('div',{ref:"ninePry",staticClass:"pry"}),_c('div',{ref:"nineADollar",staticClass:"ADollar"}),_c('div',{ref:"nineBDollar",staticClass:"BDollar"}),_c('div',{ref:"nineTree",staticClass:"tree"})])]),_c('div',{ref:"ten",staticClass:"ten",style:({
          background: `url(${
            'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/881e8adc483e4c36b7e6dba61b65b583.png' +
            this.imgSuffix
          })`,
        })},[_c('div',[_c('div',{ref:"tenContet",staticClass:"contet"},[_c('div',{attrs:{"id":"font_center"}},[_vm._v("\n              "+_vm._s(_vm.arrList[10].item.length > 80
                  ? _vm.arrList[10].item.slice(0, 80) + "..."
                  : _vm.arrList[10].item)+"\n            ")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.toHomePath(4, { id: 6 })}}},[_vm._v("\n              查看更多"),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('div',{ref:"tenPry",staticClass:"pry"}),_c('div',{ref:"tenADollar",staticClass:"ADollar"}),_c('div',{ref:"tenBDollar",staticClass:"BDollar"}),_c('div',{ref:"tenTree",staticClass:"tree"})])]),_c('div',{ref:"zero",staticClass:"zero"},[_c('div',[_c('div',{ref:"zeroContet",staticClass:"contet"},[_c('div',{attrs:{"id":"font_center"}},[_vm._v("\n              "+_vm._s(_vm.arrList[0].item.length > 80
                  ? _vm.arrList[0].item.slice(0, 80) + "..."
                  : _vm.arrList[0].item)+"\n            ")]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.toHomePath(99, { id: 3 })}}},[_vm._v("\n              查看更多"),_c('i',{staticClass:"el-icon-arrow-right"})])]),_c('div',{ref:"zeroPry",staticClass:"pry"}),_c('div',{ref:"zeroADollar",staticClass:"ADollar"}),_c('div',{ref:"zeroBDollar",staticClass:"BDollar"}),_c('div',{ref:"zeroTree",staticClass:"tree"})])]),_c('div',{ref:"zeroLogo",staticClass:"zeroLogo"}),_c('div',{ref:"zeroShow",staticClass:"zeroShow",on:{"mouseenter":_vm.zeroMouseenter}}),_c('div',{staticStyle:{"width":"86px","position":"absolute","height":"144px","bottom":"505px","left":"804px","z-index":"30"},on:{"mouseenter":_vm.zeroMouseenter}}),_c('div',{staticClass:"oneShow",on:{"mouseenter":_vm.oneMouseenter}}),_c('div',{staticClass:"twoShow",on:{"mouseenter":_vm.twoMouseenter}}),_c('div',{staticClass:"threeShow",style:({
          background: `url(${
            'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/754a366fae184326b8ea682efd1d6dac.png' +
            this.imgSuffix
          })`,
        }),on:{"mouseenter":_vm.threeMouseenter}}),_c('div',{staticClass:"fourShow",on:{"mouseenter":_vm.fourMouseenter}}),_c('div',{staticClass:"fiveShow",on:{"mouseenter":_vm.fiveMouseenter}}),_c('div',{staticClass:"sixShow",on:{"mouseenter":_vm.sixMouseenter}}),_c('div',{staticClass:"sevenShow",on:{"mouseenter":_vm.sevenMouseenter}}),_c('div',{staticClass:"eightShow",on:{"mouseenter":_vm.eightMouseenter}}),_c('div',{staticClass:"nineShow",on:{"mouseenter":_vm.nineMouseenter}}),_c('div',{staticClass:"tenShow",on:{"mouseenter":_vm.tenMouseenter}}),_c('div',{staticClass:"balloon"})]),_c('div',{staticClass:"box",on:{"mouseenter":_vm.boxMouseleave}},[_c('div',{staticClass:"image"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }