<template>
  <div style="color: #5a4e42; background: #edeae6">
    <div
      class="hig"
      :style="{
        background: `url(${
          'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/8d6bbfe19cef4c3ca015e0969348580b.png' +
          this.imgSuffix
        })`,
        'background-size': 'cover',
      }"
    >
      <img
        src="../../static/newImage/img_loading.png"
        class="img_loading"
        v-show="imgSuffix"
      />
      <img
        :src="
          'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/18a1096765ae4e3a8919a38391f24961.png' +
          imgSuffix
        "
        class="titlceCent"
        alt=""
      />
    </div>
    <div class="on">
      <div class="showBox">
        <div
          class="one"
          :style="{
            background: `url(${
              'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/40d762322f2345a9815c1fea0df68617.png' +
              this.imgSuffix
            })`,
          }"
          ref="one"
        >
          <div>
            <div ref="oneContet" class="contet">
              <div id="font_center">
                {{
                  arrList[1].item.length > 80
                    ? arrList[1].item.slice(0, 80) + "..."
                    : arrList[1].item
                }}
              </div>
              <div class="more" @click="toHomePath(8, { id: 4 })">
                查看更多<i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div ref="onePry" class="pry"></div>
            <div ref="oneADollar" class="ADollar"></div>
            <div ref="oneBDollar" class="BDollar"></div>
            <div ref="oneTree" class="tree"></div>
          </div>
        </div>
        <div
          class="two"
          :style="{
            background: `url(${
              'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/754a366fae184326b8ea682efd1d6dac.png' +
              this.imgSuffix
            })`,
          }"
          ref="two"
        >
          <div>
            <div ref="twoContet" class="contet">
              <div id="font_center">
                {{
                  arrList[2].item.length > 80
                    ? arrList[2].item.slice(0, 80) + "..."
                    : arrList[2].item
                }}
              </div>
              <div class="more" @click="toHomePath(3, { id: 7 })">
                查看更多<i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div ref="twoPry" class="pry"></div>
            <div ref="twoADollar" class="ADollar"></div>
            <div ref="twoBDollar" class="BDollar"></div>
            <div ref="twoTree" class="tree"></div>
          </div>
        </div>
        <div
          class="three"
          :style="{
            background: `url(${
              'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/9375b364e0354ae885ce59c2406b5bfe.png' +
              this.imgSuffix
            })`,
          }"
          ref="three"
        >
          <div>
            <div ref="threeContet" class="contet">
              <div id="font_center">
                {{
                  arrList[3].item.length > 80
                    ? arrList[3].item.slice(0, 80) + "..."
                    : arrList[3].item
                }}
              </div>
              <div class="more" @click="toHomePath(2, { id: 5 })">
                查看更多<i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div ref="threePry" class="pry"></div>
            <div ref="threeADollar" class="ADollar"></div>
            <div ref="threeBDollar" class="BDollar"></div>
            <div ref="threeTree" class="tree"></div>
          </div>
        </div>
        <div
          class="four"
          :style="{
            background: `url(${
              'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/ba6a44736102459aae53fc5a417e6197.png' +
              this.imgSuffix
            })`,
          }"
          ref="four"
        >
          <div>
            <div ref="fourContet" class="contet">
              <div id="font_center">
                {{
                  arrList[4].item.length > 80
                    ? arrList[4].item.slice(0, 80) + "..."
                    : arrList[4].item
                }}
              </div>
              <!-- <div class="more">
                查看更多<i class="el-icon-arrow-right"></i>
              </div> -->
            </div>
            <div ref="fourPry" class="pry"></div>
            <div ref="fourADollar" class="ADollar"></div>
            <div ref="fourBDollar" class="BDollar"></div>
            <div ref="fourTree" class="tree"></div>
          </div>
        </div>
        <div
          class="five"
          :style="{
            background: `url(${
              'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/9f5eeab3a8c44116b59c4054ed56b91c.png' +
              this.imgSuffix
            })`,
          }"
          ref="five"
        >
          <div>
            <div ref="fiveContet" class="contet">
              <div id="font_center">
                {{
                  arrList[5].item.length > 80
                    ? arrList[5].item.slice(0, 80) + "..."
                    : arrList[5].item
                }}
              </div>
              <!-- <div class="more">
                查看更多<i class="el-icon-arrow-right"></i>
              </div> -->
            </div>
            <div ref="fivePry" class="pry"></div>
            <div ref="fiveADollar" class="ADollar"></div>
            <div ref="fiveBDollar" class="BDollar"></div>
            <div ref="fiveTree" class="tree"></div>
          </div>
        </div>
        <div
          class="six"
          :style="{
            background: `url(${
              'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/db794b7a28ac4db1aad70cb62ddc255d.png' +
              this.imgSuffix
            })`,
          }"
          ref="six"
        >
          <div>
            <div ref="sixContet" class="contet">
              <div id="font_center">
                {{
                  arrList[6].item.length > 80
                    ? arrList[6].item.slice(0, 80) + "..."
                    : arrList[6].item
                }}
              </div>
              <div class="more" @click="toHomePath(7, { id: 1 })">
                查看更多<i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div ref="sixPry" class="pry"></div>
            <div ref="sixADollar" class="ADollar"></div>
            <div ref="sixBDollar" class="BDollar"></div>
            <div ref="sixTree" class="tree"></div>
          </div>
        </div>
        <div
          class="seven"
          :style="{
            background: `url(${
              'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/574de7495c614de4bfb8756aa8a3da00.png' +
              this.imgSuffix
            })`,
          }"
          ref="seven"
        >
          <div>
            <div ref="sevenContet" class="contet">
              <div id="font_center">
                {{
                  arrList[7].item.length > 80
                    ? arrList[7].item.slice(0, 80) + "..."
                    : arrList[7].item
                }}
              </div>
              <div class="more" @click="toHomePath(2, { id: 3 })">
                查看更多<i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div ref="sevenPry" class="pry"></div>
            <div ref="sevenADollar" class="ADollar"></div>
            <div ref="sevenBDollar" class="BDollar"></div>
            <div ref="sevenTree" class="tree"></div>
          </div>
        </div>
        <div
          class="eight"
          :style="{
            background: `url(${
              'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/6c4dac1f4ce54b9eb2a8516b2ff5b96e.png' +
              this.imgSuffix
            })`,
          }"
          ref="eight"
        >
          <div>
            <div ref="eightContet" class="contet">
              <div id="font_center">
                {{
                  arrList[8].item.length > 80
                    ? arrList[8].item.slice(0, 80) + "..."
                    : arrList[8].item
                }}
              </div>
              <div class="more" @click="toHomePath(5, { id: 2 })">
                查看更多<i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div ref="eightPry" class="pry"></div>
            <div ref="eightADollar" class="ADollar"></div>
            <div ref="eightBDollar" class="BDollar"></div>
            <div ref="eightTree" class="tree"></div>
          </div>
        </div>
        <div
          class="nine"
          :style="{
            background: `url(${
              'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/31847644470e4985af62054e9aa37b5f.png' +
              this.imgSuffix
            })`,
          }"
          ref="nine"
        >
          <div>
            <div ref="nineContet" class="contet">
              <div id="font_center">
                {{
                  arrList[9].item.length > 80
                    ? arrList[9].item.slice(0, 80) + "..."
                    : arrList[9].item
                }}
              </div>
              <div class="more" @click="toHomePath(10, { id: 9 })">
                查看更多<i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div ref="ninePry" class="pry"></div>
            <div ref="nineADollar" class="ADollar"></div>
            <div ref="nineBDollar" class="BDollar"></div>
            <div ref="nineTree" class="tree"></div>
          </div>
        </div>
        <div
          class="ten"
          :style="{
            background: `url(${
              'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/881e8adc483e4c36b7e6dba61b65b583.png' +
              this.imgSuffix
            })`,
          }"
          ref="ten"
        >
          <div>
            <div ref="tenContet" class="contet">
              <div id="font_center">
                {{
                  arrList[10].item.length > 80
                    ? arrList[10].item.slice(0, 80) + "..."
                    : arrList[10].item
                }}
              </div>
              <div class="more" @click="toHomePath(4, { id: 6 })">
                查看更多<i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div ref="tenPry" class="pry"></div>
            <div ref="tenADollar" class="ADollar"></div>
            <div ref="tenBDollar" class="BDollar"></div>
            <div ref="tenTree" class="tree"></div>
          </div>
        </div>
        <div class="zero" ref="zero">
          <div>
            <div ref="zeroContet" class="contet">
              <div id="font_center">
                {{
                  arrList[0].item.length > 80
                    ? arrList[0].item.slice(0, 80) + "..."
                    : arrList[0].item
                }}
              </div>
              <div class="more" @click="toHomePath(99, { id: 3 })">
                查看更多<i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div ref="zeroPry" class="pry"></div>
            <div ref="zeroADollar" class="ADollar"></div>
            <div ref="zeroBDollar" class="BDollar"></div>
            <div ref="zeroTree" class="tree"></div>
          </div>
        </div>
        <div class="zeroLogo" ref="zeroLogo"></div>
        <div class="zeroShow" ref="zeroShow" @mouseenter="zeroMouseenter"></div>
        <div
          @mouseenter="zeroMouseenter"
          style="
            width: 86px;
            position: absolute;
            height: 144px;
            bottom: 505px;
            left: 804px;
            z-index: 30;
          "
        ></div>
        <div class="oneShow" @mouseenter="oneMouseenter"></div>
        <div class="twoShow" @mouseenter="twoMouseenter"></div>

        <div
          class="threeShow"
          :style="{
            background: `url(${
              'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/754a366fae184326b8ea682efd1d6dac.png' +
              this.imgSuffix
            })`,
          }"
          @mouseenter="threeMouseenter"
        ></div>

        <div class="fourShow" @mouseenter="fourMouseenter"></div>
        <div class="fiveShow" @mouseenter="fiveMouseenter"></div>
        <div class="sixShow" @mouseenter="sixMouseenter"></div>
        <div class="sevenShow" @mouseenter="sevenMouseenter"></div>
        <div class="eightShow" @mouseenter="eightMouseenter"></div>
        <div class="nineShow" @mouseenter="nineMouseenter"></div>
        <div class="tenShow" @mouseenter="tenMouseenter"></div>
        <div class="balloon"></div>
      </div>
        <!-- :style="{
          background: `url(${
            'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/f0df7c0645c744158e0b01536bd00360.png' +
            this.imgSuffix
          })`,
          'background-size': 'cover',
        }" -->
      <div
        class="box"
        @mouseenter="boxMouseleave"
      >
        <div class="image"></div>
        <!-- <img src="./new/bg.jpg" class="image" alt="" srcset="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "demo",

  data() {
    return {
      imgSuffix: "?x-oss-process=image/resize,m_lfit,w_500",
      zero: null,
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      seven: null,
      eight: null,
      nine: null,
      ten: null,
      numberList: [
        { key: "one" },
        { key: "two" },
        { key: "three" },
        { key: "four" },
        { key: "five" },
        { key: "six" },
        { key: "seven" },
        { key: "eight" },
        { key: "nine" },
        { key: "ten" },
      ],
      present: null,
      arrList: [
        {
          item: "浙江中峪控股集团有限公司 是一家涉足商贸、农业、道地药材与新能源开发、数交所与大宗日用品交易市场、职业培训学校、中医大健康、医疗、生物制药、网络科技应用、养老产业、金融与创投等十大闭环内循环产业的集团化公司，集团以国家政策为导向，以大众创业，万众创新为初心，跨界融合、科技创新、数字经济、跨产业赋能、多元化经营，服务于各生产企业的同时，为市场培育孵化更多优质的人才。紧跟国家战略部署，以乡村振兴为战略目标；以守护道地药材和传承弘扬发展中医文化为使命，把中医精髓代代相传并发扬光大，普惠大众；以振兴地区职业技能，培育多元化技术人才，助推就业与创业问题；为国家大健康战略规划真正落地生根普及，为提升老年人生活品质等民生领域及国家双碳战略和绿色生态发展，贡献自己的力量。",
        },
        {
          item: "投资管理公司不仅仅为各产业提供金融助力，更是为了集团进一步发展服务，在集团稳健发展的基础上，进行分层级的资本良性运作，为社会创造更多的财富，为共同富裕助力。",
        },
        {
          item: "麒典信息科技是一家集大数据服务、核心技术研发、技术咨询服务、区块链技术应用、软件开发等科技研发公司，为集团产业链数字化运作提供强大技术输出，肩负集团产业链大数据信息储存输送和科技数字化运作重任，同时为助力地方产业数字化发展提供强大技术支撑，承载银发经济链产品研发和制造。",
        },
        {
          item: "中峪商贸为四川省泸州市地方政府重点招商引资项目，是一家承载孵化落地集团全产业链使命的运营公司，以其独特商业运营架构和科学创新的市场培育体系，助推地方中小企业产品流通，促进地方区域经济发展，优化商品流通渠道。协同中峪拍交所为集团产业导入高粘度市场流量，协同中峪职业培训学校共同孵化输送业务体系人才，为地方解决就业、创业而努力。",
        },
        { item: "版块正在建设中" },
        { item: "版块正在建设中" },
        {
          item: "中峪日用品交易市场均为中峪集团十大闭环产业之一，具有多行业背景和资源优势，整合融通了多品种商品资源、资产数字化运作、仓储物流、资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，帮助中小企业实现去库存、优化产能拉动内需，以“发展产业电商、服务实体经济”为使命，积极响应国家乡村振兴、数字中国建设、数商兴农号召，以“产业基地+在线贸易+科技应用+金融服务”为功能定位，构建产业链生态闭环，致力于产业创新服务综合体建设，为中峪道地药材产业链发展建立资产数字化交易平台，为线下实体产业赋能创收和大数据客户引流扩容，为加快推进数字时代促进数字经济和实体经济深度融合尽一份责任。由交易市场和中峪拍交所赋能，成功开启道地药材线上线下创新融合，从中药材种子种苗溯源、带量采购、中草药制剂研发到医院养老产业端口输出，助推集团各个产业链的良性闭环。",
        },
        {
          item: "中峪职业培训学校是一家结合国家职业教育体系改革指导政策，肩负起特色职业技能培育，为中峪产业培育人才，给社会输送专业人才，同时解决就业再就业问题，为地方职业教育发展提供助力，为养老和文化产业发展提供助力。",
        },
        {
          item: "四川中峪拍卖交易服务有限责任公司简称“中峪数交”，为中峪集团生态内循环闭环产业链之一，具有多行业背景和资源优势，整合融通了多品种商品资源、数字化拍卖服务、仓储物流资源和资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，优化产能拉动内需，以“发展产业电商、服务实体经济”为使命，积极响应国家乡村振兴、数字中国建设、数商兴农号召，以“产业基地+在线贸易+科技应用+数字拍卖服务”为功能定位，构建产业链生态闭环，致力于产业创新服务综合体建设，为实现数字经济新农业，乡村振兴新载体发展起到强大支撑和赋能，同时发挥资产数字化拍卖平台的优势，为实体产业赋能创收和大数据客户引流扩容，为加快推进数字时代促进数字经济和实体经济深度融合尽一份责任。",
        },
        {
          item: "生物制药坚持中医药原创思维，深入发掘中医药精华，是一家传承古代经典名方、名老中医经验方、传统民族药业、新药研发为一体的高新技术制药研发公司。为传承中医药文化，促进中医药特色发展，提升中医药产业水平做出积极贡献，是中峪道地药材的制药基地，是中峪医院的药材基地，为中峪康养产业提供优质药食同源。",
        },
        {
          item: "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个道地药材种植基地，严格遵循地域特性、自然规律和药材生长习性，确保药材在最佳生长环境中茁壮成长，致力于为更多人提供高品质、纯天然的中药材，为传统中医药文化焕发新的活力。",
        },
      ],
      imgSuffixTime: null,
    };
  },

  mounted() {
    this.sta();
    this.imgSuffixTime = setTimeout(() => {
      this.imgSuffix = "";
    }, 4000);
  },
  beforeDestroy() {
    clearTimeout(this.imgSuffixTime);
    // window.removeEventListener("scroll", this.changeTitle);
    // document.getElementsByClassName("nav_1")[0].remove();
    // if (document.getElementsByClassName("nav_1")[1]) {
    //   document.getElementsByClassName("nav_1")[1].remove();
    // }
  },
  methods: {
    sta() {
      this.zero = this.$refs.zero;
      this.one = this.$refs.one;
      this.two = this.$refs.two;
      this.three = this.$refs.three;
      this.four = this.$refs.four;
      this.five = this.$refs.five;
      this.six = this.$refs.six;
      this.seven = this.$refs.seven;
      this.eight = this.$refs.eight;
      this.nine = this.$refs.nine;
      this.ten = this.$refs.ten;
      // let textElement = document.getElementById("font_center");
      // let lineHeight = window.getComputedStyle(textElement).lineHeight;
      // let computedStyle = window.getComputedStyle(textElement);
      // if (lineHeight === "normal") {
      //   let fontSize = parseFloat(computedStyle.fontSize); // 获取字体大小
      //   lineHeight = fontSize * 1.35; // 假设将行高设置为字体大小的1.2倍
      // } else {
      //   lineHeight = parseFloat(lineHeight); // 转换为数值
      // }
      // let maxHeight = lineHeight * 3;

      // // 获取元素内容
      // let content = textElement.textContent;
      // console.log(maxHeight, content, lineHeight);
      // if (textElement.scrollHeight > maxHeight) {
      //   textElement.style.height = maxHeight + "px";
      //   textElement.style.overflow = "hidden";
      //   textElement.style.textOverflow = "ellipsis";
      //   content = content.slice(0, content.lastIndexOf(" ")) + "...";
      //   textElement.textContent = content;
      // }
      // console.log(maxHeight, content, lineHeight, "3");
    },
    toggleClasses(target, actionPrefix) {
      const allClasses = ["contet", "tree", "pry", "ADollar", "BDollar"];
      const all = [
        "zero",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
      ];
      //移出
      all.forEach((e) => {
        // if (e != actionPrefix) {
        // console.log(e, e.classList);
        this[e].classList.remove(`${e}OpenType`);
        allClasses.forEach((className) => {
          this.$refs[
            `${e + className.charAt(0).toUpperCase() + className.slice(1)}`
          ].classList.remove(
            `${e}Open${className.charAt(0).toUpperCase() + className.slice(1)}`
          );
        });
        // }
      });
      const list = this.$refs[actionPrefix];
      console.log(this.$refs[actionPrefix], list.className);
      target.classList.add(`${actionPrefix}OpenType`);
      allClasses.forEach((className) => {
        this.$refs[
          `${
            actionPrefix +
            className.charAt(0).toUpperCase() +
            className.slice(1)
          }`
        ].classList.add(
          `${actionPrefix}Open${
            className.charAt(0).toUpperCase() + className.slice(1)
          }`
        );
      });
    },

    reToggleClasses() {
      const all = [
        "zero",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
      ];

      const allClasses = ["contet", "tree", "pry", "ADollar", "BDollar"];
      all.forEach((e) => {
        this[e].classList.remove(`${e}OpenType`);
        allClasses.forEach((className) => {
          this.$refs[
            `${e + className.charAt(0).toUpperCase() + className.slice(1)}`
          ].classList.remove(
            `${e}Open${className.charAt(0).toUpperCase() + className.slice(1)}`
          );
        });
      });
    },
    zeroMouseenter() {
      console.log(this.zero, this.$refs.zero, 123123);
      this.toggleClasses(this.zero, "zero");
    },
    oneMouseenter() {
      console.log(this.one, this.$refs.one);
      this.toggleClasses(this.one, "one");
    },
    boxMouseleave(e) {
      this.reToggleClasses();
    },
    twoMouseenter() {
      console.log(this.two, this.$refs.two);
      this.toggleClasses(this.two, "two");
    },
    threeMouseenter() {
      console.log(this.three, this.$refs.three);
      this.toggleClasses(this.three, "three");
    },
    fourMouseenter() {
      console.log(this.four, this.$refs.four);
      this.toggleClasses(this.four, "four");
    },
    fiveMouseenter() {
      console.log(this.five, this.$refs.five);
      this.toggleClasses(this.five, "five");
    },
    sixMouseenter() {
      console.log(this.six, this.$refs.six);
      this.toggleClasses(this.six, "six");
    },
    sevenMouseenter() {
      console.log(this.seven, this.$refs.seven);
      this.toggleClasses(this.seven, "seven");
    },
    eightMouseenter() {
      console.log(this.eight, this.$refs.eight);
      this.toggleClasses(this.eight, "eight");
    },
    nineMouseenter() {
      console.log(this.nine, this.$refs.nine);
      this.toggleClasses(this.nine, "nine");
    },
    tenMouseenter() {
      console.log(this.ten, this.$refs.ten);
      this.toggleClasses(this.ten, "ten");
    },

    toHomePath(index, item) {
      if (index == 99) {
        this.$router.push({
          path: "/contact_us",
          query: { websiteCategoryId: item.id },
        });
        window.scrollTo(0, 0);
        return;
      }
      if (index == 5) {
        window.open("https://sj.zoneyu.net/");
      } else if (index == 4) {
        window.open("https://www.zoneyu.cc/");
      } else if (index == 3) {
        window.open("https://www.zoneyu.com.cn/");
      } else {
        this.$router.push({
          path: "/industrial_development",
          query: { id: item.id },
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.zeroOpenContet {
  animation: fadeContet 1.5s 1 forwards;
}
.zeroOpenPry {
  // opacity: 1 !important;
  animation: fadeContetL 2s 1 forwards;
}

.oneOpenContet {
  // opacity: 1 !important;
  animation: fadeContet 1.5s 1 forwards;
}
.oneOpenADollar {
  opacity: 1 !important;
  animation: fadeOut 2s linear infinite both;
}
.oneOpenBDollar {
  opacity: 1 !important;
}
.oneOpenTree {
  opacity: 1 !important;
}
.oneOpenPry {
  // opacity: 1 !important;
  animation: fadeContetL 2s 1 forwards;
}

.twoOpenContet {
  // opacity: 1 !important;
  animation: fadeContet 1.5s 1 forwards;
}
.twoOpenADollar {
  opacity: 1 !important;
  // animation: fadeOut 2s linear infinite both;
}
.twoOpenBDollar {
  opacity: 1 !important;
  animation: fadeOut 2.1s linear infinite both;
}
.twoOpenTree {
  opacity: 1 !important;
}
.twoOpenPry {
  animation: fadeContetL 2s 1 forwards;
  // opacity: 1 !important;
}

.threeOpenContet {
  // opacity: 1 !important;
  animation: fadeContet 1.5s 1 forwards;
}
.threeOpenTree {
  opacity: 1 !important;
  animation: fadeOut 2.2s linear infinite both;
}
.threeOpenPry {
  animation: fadeContetL 2s 1 forwards;
  // opacity: 1 !important;
}
.threeOpenContet {
  opacity: 1 !important;
  animation: fadeContet 1.5s 1 forwards;
}
.threeOpenTree {
  opacity: 1 !important;
  animation: fadeOut 2.2s linear infinite both;
}

.fourOpenPry {
  animation: fadeContetL 2s 1 forwards;
}
.fourOpenContet {
  animation: fadeContetF 1.5s 1 forwards;
}

.fiveOpenPry {
  // opacity: 1 !important;
  animation: fadeContetL 2s 1 forwards;
}
.fiveOpenContet {
  // opacity: 1 !important;
  animation: fadeContetF 1.5s 1 forwards;
}

.sixOpenContet {
  // opacity: 1 !important;
  animation: fadeContet 1.5s 1 forwards;
}
.sixOpenTree {
  opacity: 1 !important;
  animation: fadeOut 2.2s linear infinite both;
}
.sixOpenPry {
  animation: fadeContetL 2s 1 forwards;
  // opacity: 1 !important;
}

.sevenOpenContet {
  // opacity: 1 !important;
  animation: fadeContet 1.5s 1 forwards;
}
.sevenOpenTree {
  opacity: 1 !important;
  animation: fadeOut 2.2s linear infinite both;
}
.sevenOpenPry {
  animation: fadeContetL 2s 1 forwards;
  // opacity: 1 !important;
}

.eightOpenContet {
  // opacity: 1 !important;
  animation: fadeContet 1.5s 1 forwards;
}
.eightOpenTree {
  opacity: 1 !important;
  animation: fadeOut 2.2s linear infinite both;
}
.eightOpenPry {
  // opacity: 1 !important;
  animation: fadeContetL 2s 1 forwards;
}

.nineOpenContet {
  // opacity: 1 !important;
  animation: fadeContet 1.5s 1 forwards;
}
.nineOpenTree {
  opacity: 1 !important;
  animation: fadeOut 2.2s linear infinite both;
}
.nineOpenPry {
  animation: fadeContetL 2s 1 forwards;
  // opacity: 1 !important;
}

.tenOpenContet {
  // opacity: 1 !important;
  animation: fadeContet 1.5s 1 forwards;
}
.tenOpenTree {
  opacity: 1 !important;
  animation: fadeOut 2.2s linear infinite both;
}
.tenOpenPry {
  // opacity: 1 !important;
  animation: fadeContetL 2s 1 forwards;
}
@keyframes fadeContetF {
  0% {
    width: 382px;
    height: 0;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    width: 382px;
    height: 195px;
    opacity: 1;
  }
}
@keyframes fadeContet {
  0% {
    width: 764px;
    height: 0;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    width: 764px;
    height: 390px;
    opacity: 1;
  }
}
@keyframes fadeContetL {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from,
  to {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
}
@keyframes fadeOutOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes logoTopBottom {
  from,
  to {
    position: absolute;
    bottom: 455px;
  }
  50% {
    position: absolute;
    bottom: 475px;
  }
}

/*  */
.zeroOpenType {
  opacity: 1 !important;
  z-index: 24 !important;
  transition: opacity 0.5s ease-in-out !important;
}
.oneOpenType {
  opacity: 1 !important;
  z-index: 29 !important;
  width: 282px !important;
  height: 258px !important;
  transition: opacity 0.5s ease-in-out !important;
}
.twoOpenType {
  opacity: 1 !important;
  z-index: 29 !important;
  width: 313px !important;
  height: 202px !important;
  transition: opacity 0.5s ease-in-out !important;
}
.threeOpenType {
  opacity: 1 !important;
  z-index: 29 !important;
  width: 283px !important;
  height: 215px !important;
  transition: opacity 0.5s ease-in-out !important;
}
.fourOpenType {
  opacity: 1 !important;
  z-index: 29 !important;
  width: 273px !important;
  height: 190px !important;
  transition: opacity 0.5s ease-in-out !important;
}
.fiveOpenType {
  opacity: 1 !important;
  z-index: 29 !important;
  width: 273px !important;
  height: 214px !important;
  transition: opacity 0.5s ease-in-out !important;
}
.sixOpenType {
  opacity: 1 !important;
  z-index: 29 !important;
  width: 280px !important;
  height: 193px !important;
  transition: opacity 0.5s ease-in-out !important;
}
.sevenOpenType {
  opacity: 1 !important;
  z-index: 29 !important;
  width: 341px !important;
  height: 242px !important;
  transition: opacity 0.5s ease-in-out !important;
}
.eightOpenType {
  opacity: 1 !important;
  z-index: 29 !important;
  width: 258px !important;
  height: 217px !important;
  transition: opacity 0.5s ease-in-out !important;
}
.nineOpenType {
  opacity: 1 !important;
  z-index: 29 !important;
  width: 259px !important;
  height: 168px !important;
  transition: opacity 0.5s ease-in-out !important;
}
.tenOpenType {
  opacity: 1 !important;
  z-index: 29 !important;
  width: 422px !important;
  height: 265px !important;
  transition: opacity 0.5s ease-in-out !important;
}
.zeroLogo {
  animation: logoTopBottom 2s linear infinite both;
  background: url("./new/235/logo.png");
  z-index: 25;
  position: absolute;
  width: 111px;
  height: 101px;
  transform: scale(0.67);
  bottom: 455px;
  left: 733px;
}
.zero {
  // background: url("./new/235/logo_bg.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  // width: 473px;
  // height: 405px;
  width: 389px;
  height: 268px;
  -webkit-transform: scale(0.67) rotate(10deg);
  transform: scale(0.67) rotate(10deg);
  bottom: 364px;
  left: 614px;
  // background-color: #3498db;
  // clip-path: polygon(16% 11%, 101% 15%, 86% 96%, -8% 95%);
  .contet {
    background: url("./new/框.png");
    z-index: 29;
    overflow: hidden;
    background-size: cover;
    width: 764px !important;
    transform: rotate(-10deg);
    height: 0;
    font-size: 24px;
    position: absolute;
    opacity: 0;
    // bottom: 280px;
    // left: -125px;
    bottom: 295px;
    left: -210px;
  }
  .pry {
    background: url("./new/杠.png");
    transform: scale(0.85);
    background-size: cover;
    transform: rotate(-10deg);
    width: 88px;
    height: 160px;
    position: absolute;
    opacity: 0;
    bottom: 185px;
    // left: 195px;
    left: 130px;
  }
  .ADollar {
  }
  .tree {
  }
  .BDollar {
  }
}
.one {
  transition: opacity 0.5s ease-in-out;
  // background: url("./new/有色.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 282px;
  height: 258px;
  transform: scale(0.67);
  bottom: 215px;
  left: 621px;
  .contet {
    background: url("./new/框.png");
    z-index: 29;
    overflow: hidden;
    background-size: cover;
    width: 764px !important;
    height: 0;
    font-size: 24px;
    position: absolute;
    opacity: 0;
    font-weight: 600;
    bottom: 240px;
    left: -125px;
  }
  .pry {
    background: url("./new/杠.png");
    transform: scale(0.85);
    background-size: cover;
    width: 88px;
    height: 160px;
    position: absolute;
    opacity: 0;

    bottom: 145px;
    left: 140px;
  }
  .ADollar {
    background: url("./new/235/18.png");
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 112px;
    height: 80px;
    bottom: 110px;
    left: 97px;
  }
  .tree {
    background: url("./new/最前面的树.png");
    bottom: 70px;
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 23px;
    height: 47px;
    left: 145px;
  }
  .BDollar {
    // background: url("./new/硬币2.png");
    // bottom: 90px;
    // opacity: 0;
    // position: absolute;
    // z-index: 11;
    // width: 71px;
    // height: 63px;
    // left: 135px;
  }
}
.two {
  transition: opacity 0.5s ease-in-out;
  // background: url("./new/235/5.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 313px;
  height: 202px;
  transform: scale(0.666);
  bottom: 160px;
  left: 371px;
  .contet {
    background: url("./new/框.png");
    z-index: 29;
    overflow: hidden;
    background-size: cover;
    width: 764px !important;
    height: 0;
    font-size: 24px;
    position: absolute;
    opacity: 0;
    font-weight: 600;
    bottom: 260px;
    left: -125px;
  }
  .pry {
    background: url("./new/杠.png");
    transform: scale(0.85);
    background-size: cover;
    width: 88px;
    height: 160px;
    position: absolute;
    opacity: 0;

    bottom: 165px;
    left: 140px;
  }
  .ADollar {
    // background: url("./new/235/电脑.png");
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 107px;
    height: 105px;
    bottom: 60px;
    left: 165px;
  }
  .BDollar {
    background: url("./new/235/代码.png");
    bottom: 85px;
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 176px;
    height: 110px;
    left: 63px;
  }
  .tree {
    // background: url("./new/最前面的树.png");
    // bottom: 70px;
    // opacity: 0;
    // position: absolute;
    // z-index: 12;
    // width: 23px;
    // height: 47px;
    // left: 145px;
  }
}
.three {
  transition: opacity 0.5s ease-in-out;
  // background: url("./new/235/4.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 283px;
  height: 215px;
  transform: scale(0.666);
  bottom: 295px;
  left: 269px;
  .contet {
    background: url("./new/框.png");
    z-index: 29;
    overflow: hidden;
    background-size: cover;
    width: 764px !important;
    height: 0;
    font-size: 24px;
    position: absolute;
    opacity: 0;
    font-weight: 600;
    bottom: 260px;
    left: -125px;
  }
  .pry {
    background: url("./new/杠.png");
    transform: scale(0.85);
    background-size: cover;
    width: 88px;
    height: 160px;
    position: absolute;
    opacity: 0;

    bottom: 165px;
    left: 140px;
  }
  .ADollar {
    // background: url("./new/硬币1.png");
    // opacity: 0;
    // position: absolute;
    // z-index: 12;
    // width: 42px;
    // height: 38px;
    // bottom: 90px;
    // left: 189px;
  }
  .tree {
    background: url("./new/235/3.png");
    bottom: 95px;
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 103px;
    height: 110px;
    left: 100px;
  }
  .BDollar {
    // background: url("./new/硬币2.png");
    // bottom: 90px;
    // opacity: 0;
    // position: absolute;
    // z-index: 11;
    // width: 71px;
    // height: 63px;
    // left: 135px;
  }
}
.four {
  transition: opacity 0.5s ease-in-out;
  // background: url("./new/235/1.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 273px;
  height: 190px;
  transform: scale(0.666);
  bottom: 455px;
  left: 222px;
  .contet {
    background: url("./new/框.png");
    z-index: 29;
    overflow: hidden;
    background-size: cover;
    width: 382px !important;
    height: 0;
    font-size: 24px;
    position: absolute;
    opacity: 0;
    font-weight: 600;
    // bottom: 200px;
    // left: -125px;
    bottom: 225px;
    left: 23px;
  }
  .pry {
    background: url("./new/杠.png");
    transform: scale(0.85);
    background-size: cover;
    width: 88px;
    height: 160px;
    position: absolute;
    opacity: 0;

    bottom: 110px;
    transition: opacity 1.5s;
    left: 140px;
  }
  .ADollar {
    background: url("./new/硬币1.png");
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 42px;
    height: 38px;
    bottom: 90px;
    left: 189px;
  }
  .tree {
    background: url("./new/最前面的树.png");
    bottom: 70px;
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 23px;
    height: 47px;
    left: 145px;
  }
  .BDollar {
    background: url("./new/硬币2.png");
    bottom: 90px;
    opacity: 0;
    position: absolute;
    z-index: 11;
    width: 71px;
    height: 63px;
    left: 135px;
  }
}
.five {
  transition: opacity 0.5s ease-in-out;
  // background: url("./new/235/2.png");

  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 273px;
  height: 214px;
  transform: scale(0.666);
  bottom: 418px;
  left: 395px;
  .contet {
    background: url("./new/框.png");
    z-index: 29;
    overflow: hidden;
    background-size: cover;
    width: 382px !important;
    height: 0;
    font-size: 24px;
    position: absolute;
    opacity: 0;
    bottom: 225px;
    left: 23px;
    font-weight: 600;
    // bottom: 200px;
    // left: -125px;
  }
  .pry {
    background: url("./new/杠.png");
    transform: scale(0.85);
    background-size: cover;
    width: 88px;
    height: 160px;
    position: absolute;
    opacity: 0;

    bottom: 110px;
    left: 140px;
  }
  .ADollar {
    background: url("./new/硬币1.png");
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 42px;
    height: 38px;
    bottom: 90px;
    left: 189px;
  }
  .tree {
    background: url("./new/最前面的树.png");
    bottom: 70px;
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 23px;
    height: 47px;
    left: 145px;
  }
  .BDollar {
    background: url("./new/硬币2.png");
    bottom: 90px;
    opacity: 0;
    position: absolute;
    z-index: 11;
    width: 71px;
    height: 63px;
    left: 135px;
  }
}
.six {
  transition: opacity 0.5s ease-in-out;
  // background: url("./new/235/7.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 280px;
  height: 193px;
  bottom: 192px;
  left: 867px;
  transform: scale(0.666);
  .contet {
    background: url("./new/框.png");
    z-index: 29;
    overflow: hidden;
    background-size: cover;
    width: 764px !important;
    height: 0;
    font-size: 24px;
    position: absolute;
    opacity: 0;
    font-weight: 600;
    bottom: 240px;
    left: -245px;
  }
  .pry {
    background: url("./new/杠.png");
    transform: scale(0.85) scaleX(-1);
    background-size: cover;
    width: 88px;
    height: 160px;
    position: absolute;
    opacity: 0;
    bottom: 145px;
    left: 75px;
  }
  .ADollar {
    // background: url("./new/硬币1.png");
    // opacity: 0;
    // position: absolute;
    // z-index: 12;
    // width: 42px;
    // height: 38px;
    // bottom: 90px;
    // left: 189px;
  }
  .tree {
    background: url("./new/235/6.png");
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 133px;
    height: 107px;
    bottom: 85px;
    left: 75px;
  }
  .BDollar {
    // background: url("./new/硬币2.png");
    // bottom: 90px;
    // opacity: 0;
    // position: absolute;
    // z-index: 11;
    // width: 71px;
    // height: 63px;
    // left: 135px;
  }
}
.seven {
  transition: opacity 0.5s ease-in-out;
  // background: url("./new/235/8.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 341px;
  height: 242px;
  bottom: 295px;
  left: 1029px;
  transform: scale(0.666);
  .contet {
    background: url("./new/框.png");
    z-index: 29;
    overflow: hidden;
    background-size: cover;
    width: 764px !important;
    height: 0;
    font-weight: 600;
    font-size: 24px;
    position: absolute;
    opacity: 0;
    bottom: 285px;
    left: -190px;
  }
  .pry {
    background: url("./new/杠.png");
    transform: scale(0.85) scaleX(-1);
    background-size: cover;
    width: 88px;
    height: 160px;
    position: absolute;
    opacity: 0;
    bottom: 190px;
    left: 120px;
  }
  .ADollar {
    // background: url("./new/硬币1.png");
    // opacity: 0;
    // position: absolute;
    // z-index: 12;
    // width: 42px;
    // height: 38px;
    // bottom: 90px;
    // left: 189px;
  }
  .tree {
    background: url("./new/235/26.png");
    bottom: 145px;

    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 145px;
    height: 89px;
    left: 115px;
  }
  .BDollar {
    // background: url("./new/硬币2.png");
    // bottom: 90px;
    // opacity: 0;
    // position: absolute;
    // z-index: 11;
    // width: 71px;
    // height: 63px;
    // left: 135px;
  }
}
.eight {
  transition: opacity 0.5s ease-in-out;
  // background: url("./new/235/9.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 258px;
  height: 217px;
  bottom: 480px;
  left: 1008px;
  transform: scale(0.666);
  .contet {
    background: url("./new/框.png");
    z-index: 29;
    overflow: hidden;
    background-size: cover;
    width: 764px !important;
    height: 0;
    font-size: 24px;
    position: absolute;
    opacity: 0;
    font-weight: 600;
    bottom: 200px;
    left: -245px;
  }
  .pry {
    background: url("./new/杠.png");
    transform: scale(0.85) scaleX(-1);
    background-size: cover;
    width: 88px;
    height: 160px;
    position: absolute;
    opacity: 0;

    bottom: 110px;
    left: 70px;
  }
  .ADollar {
    background: url("./new/硬币1.png");
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 42px;
    height: 38px;
    bottom: 90px;
    left: 189px;
  }
  .tree {
    background: url("./new/235/89.png");
    bottom: 90px;
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 160px;
    height: 86px;
    left: 50px;
  }
  .BDollar {
    background: url("./new/硬币2.png");
    bottom: 90px;
    opacity: 0;
    position: absolute;
    z-index: 11;
    width: 71px;
    height: 63px;
    left: 135px;
  }
}
.nine {
  transition: opacity 0.5s ease-in-out;
  // background: url("./new/235/12.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 259px;
  height: 168px;
  bottom: 630px;
  left: 863px;

  transform: scale(0.666);
  .contet {
    background: url("./new/框.png");
    z-index: 29;
    overflow: hidden;
    background-size: cover;
    width: 764px !important;
    height: 0;
    font-size: 24px;
    position: absolute;
    opacity: 0;
    bottom: 226px;
    font-weight: 600;
    left: -245px;
  }
  .pry {
    background: url("./new/杠.png");
    transform: scale(0.85) scaleX(-1);
    background-size: cover;
    width: 88px;
    height: 160px;
    position: absolute;
    opacity: 0;

    bottom: 133px;

    left: 75px;
  }
  .ADollar {
    background: url("./new/硬币1.png");
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 42px;
    height: 38px;
    bottom: 90px;
    left: 189px;
  }
  .tree {
    background: url("./new/235/0.png");
    background-size: cover;
    bottom: 115px;
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 75px;
    /* transform: scale(8.5); */
    height: 65px;
    left: 110px;
  }
  .BDollar {
    background: url("./new/硬币2.png");
    bottom: 90px;
    opacity: 0;
    position: absolute;
    z-index: 11;
    width: 71px;
    height: 63px;
    left: 135px;
  }
}
.ten {
  transition: opacity 0.5s ease-in-out;
  // background: url("./new/235/14.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 422px;
  height: 265px;
  bottom: 559px;
  left: 484px;
  -webkit-transform: scale(0.666);
  transform: scale(0.666);
  .contet {
    background: url("./new/框.png");
    z-index: 29;
    overflow: hidden;
    background-size: cover;
    width: 764px !important;
    height: 0;
    font-size: 24px;
    position: absolute;
    opacity: 0;
    font-weight: 600;
    bottom: 260px;
    left: -125px;
  }
  .pry {
    background: url("./new/杠.png");
    transform: scale(0.85);
    background-size: cover;
    width: 88px;
    height: 160px;
    position: absolute;
    opacity: 0;

    bottom: 170px;
    left: 140px;
  }
  .ADollar {
    // background: url("./new/硬币1.png");
    // opacity: 0;
    // position: absolute;
    // z-index: 12;
    // width: 42px;
    // height: 38px;
    // bottom: 90px;
    // left: 189px;
  }
  .tree {
    background: url("./new/235/25.png");
    bottom: 100px;
    opacity: 0;
    position: absolute;
    z-index: 12;
    width: 204px;
    height: 132px;
    left: 85px;
  }
  .BDollar {
    // background: url("./new/硬币2.png");
    // bottom: 90px;
    // opacity: 0;
    // position: absolute;
    // z-index: 11;
    // width: 71px;
    // height: 63px;
    // left: 135px;
  }
}
/*  */
.zeroShow {
  // background: url("./new/235/logo_bg.png");
  // z-index: 10;
  // opacity: 0;
  // position: absolute;
  // width: 473px;
  // height: 405px;
  // -webkit-transform: scale(0.67);
  // transform: scale(0.67);
  // bottom: 327px;
  // left: 570px;

  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 389px;
  height: 268px;
  -webkit-transform: scale(0.67) rotate(10deg);
  transform: scale(0.67) rotate(10deg);
  bottom: 364px;
  left: 614px;
  // background-color: #3498db;
  clip-path: polygon(16% 11%, 101% 15%, 86% 96%, -8% 95%);
}
.oneShow {
  // background: url("./new/有色.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 282px;
  height: 258px;
  transform: scale(0.66);
  bottom: 215px;
  left: 621px;
}
.twoShow {
  // background: url("./new/235/5.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 313px;
  height: 202px;
  transform: scale(0.666);
  bottom: 160px;
  left: 371px;
}
.threeShow {
  // background: url("./new/235/5.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 283px;
  height: 215px;
  transform: scale(0.666);
  bottom: 295px;
  left: 269px;
}
.fourShow {
  // background: url("./new/235/1.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 273px;
  height: 190px;
  transform: scale(0.666);
  bottom: 455px;
  left: 222px;
}
.fiveShow {
  // background: url("./new/235/2.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 273px;
  height: 214px;
  transform: scale(0.666);
  bottom: 418px;
  left: 395px;
}
.sixShow {
  // background: url("./new/235/7.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  transform: scale(0.666);
  width: 280px;
  height: 193px;
  bottom: 192px;
  left: 867px;
}
.sevenShow {
  // background: url("./new/235/8.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  transform: scale(0.666);
  width: 341px;
  height: 242px;
  bottom: 295px;
  left: 1029px;
}
.eightShow {
  // background: url("./new/235/9.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  transform: scale(0.666);
  width: 258px;
  height: 217px;
  bottom: 480px;
  left: 1008px;
}
.nineShow {
  // background: url("./new/235/9.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 259px;
  height: 168px;
  bottom: 630px;
  left: 863px;
  transform: scale(0.666);
}
.tenShow {
  // background: url("./new/235/14.png");
  z-index: 10;
  opacity: 0;
  position: absolute;
  width: 422px;
  height: 265px;
  bottom: 559px;
  left: 484px;
  -webkit-transform: scale(0.666);
  transform: scale(0.666);
}

.showBox {
  width: 1600px;
  height: 1200px;
  position: relative;
}

.title {
  height: 450px;
  background: skyblue;
}
.on {
  position: relative;
  // height: 1600px;
  display: flex;
  overflow: hidden;
  justify-content: center; /* 水平居中 */
  // align-items: center; /* 垂直居中 */
  background: #edeae6;
}
.image {
  height: 1200px;
  background-size: cover;
}
.box {
  height: 1200px;
  position: absolute;
  background: url("./new/bg.jpg");
  background-size: cover;
  width: 1600px;
  // top: 250px;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);
}
.more {
  margin-left: 90px;
  margin-top: 75px;
  width: 120px;
  text-align: left;
  cursor: pointer;
}
#font_center {
  margin: 90px 90px;
  margin-bottom: 0px;
  line-height: 35px;
  height: 98px;
  // overflow: hidden;
}
// #font_center::after {
//   content: "...";
//   position: absolute;
//   width: 25px;
//   bottom: 96px;
//   right: 13px;
// }
.balloon {
  background: url("./new/235/balloon.png");
  background-size: cover;
  width: 187px;
  z-index: 19;
  transform: scale(0.7);
  height: 191px;
  position: absolute;
  bottom: 194px;
  right: 215px;
}
.hig {
  width: 100%;
  z-index: 19;
  height: 250px;
  position: absolute;
  min-width: 1600px;
  // background: url("./new/title.png");

  background-position-y: -40px;
}
.titlceCent {
  position: absolute;
  top: 140px;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 110px;
}
.img_loading {
  position: absolute;
  right: 10px;
  width: 20px;
  z-index: 109;
  top: 10px;
  animation: identifier 2s linear infinite;
}
</style>
